// important change to formulas 11/27/2023 // avit split: add validations to get av it
totals based on DC version and D&I totals, remove usage of quote.avit_calc // update cstrn
total formula to bCtrSubtotal, removed usage of quote.costrn_calc *
quote.construction_buffer it was inaccurate for new version
<template>
  <div>
    <b-row>
      <!-- start header info -->
      <b-col cols="12" class="py-0 my-0">
        <h2 class="py-0 my-0 my-1" style="">
          <b>Finance</b>
        </h2>
        <p v-if="signed">Revenue and costs for all approved quotes</p>
      </b-col>
      <b-col cols="12">
        <b-card>
          <div class="m-2">
            <b-row>
              <b-col cols="4">
                <p class="card_title">TOTAL REVENUE</p>
                <h2>
                  {{
                    Number(revenueTotal).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </h2>
              </b-col>
              <b-col cols="4" class="d-flex">
                <hr />
                <div>
                  <p class="card_title">TOTAL COST</p>

                  <h2>
                    {{
                      Number(costTotal).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </h2>
                </div>
              </b-col>
              <b-col cols="4" class="d-flex">
                <hr />
                <div>
                  <p class="card_title">TOTAL PROFIT</p>
                  <h2>
                    {{
                      Number(revenueTotal - costTotal).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </h2>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12" class="d-flex justify-content-end">
                <b-button variant="outline-secondary" @click="financeExport">
                  <uil-file-export class="logo" size="15px" /> Export</b-button
                ></b-col
              >
              <b-col cols="12">
                <table class="table">
                  <thead>
                    <b-tr>
                      <th class="table-background-color">Quote name</th>
                      <th class="table-background-color" colspan="3"></th>
                      <th class="table-background-color">date approved</th>
                      <th class="table-background-color text-center">CSTRN</th>
                      <th class="table-background-color text-center">FF&E</th>
                      <th class="table-background-color text-center">AV/IT</th>
                      <th class="table-background-color text-center">CHQ</th>
                      <th class="table-background-color text-center">OTH</th>
                      <th class="table-background-color text-center">Quote Total</th>
                    </b-tr>
                  </thead>
                  <!-- start quote marked as active sec -->
                  <tbody>
                    <b-tr class="">
                      <th
                        style="font-weight: 600; color: #6e6b7b; font-size: 14px"
                        rowspan="2"
                        colspan="3"
                      >
                        Quote {{ active_tfr.tfr }}
                      </th>
                      <th
                        class="text-right py-0"
                        style="font-weight: 400; font-size: 14px"
                      >
                        Revenue
                      </th>
                      <b-td class="text-right py-0">
                        <p v-if="signed" class="pt-1">
                          {{ formattedDate(quote.updated_at) }}
                        </p>
                        <p v-else style="color: #ff9f43">Not Approved</p>
                      </b-td>
                      <b-td class="text-right py-0">{{
                        Number(
                          bCtrSubtotal +
                            siteConditionsConstructionPriceTotal +
                            optionsConstructionPriceTotal
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                      <b-td class="text-right py-0">{{
                        Number(
                          (!quote.ffe_toggle ? 0 : ffe_calc) + optionsFfePriceTotal
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                      <b-td class="text-right py-0">{{
                        Number(
                          avitPriceTotal + avitInstallationTotal + optionsAvItPriceTotal
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                      <b-td class="text-right py-0">{{
                        Number(
                          Number(
                            (quote.construction_pm_buffer * 1 +
                              quote.design_ame_buffer * 1 +
                              Number(quote.engineering_fee_price)) *
                              quote.sqft
                          ) + Number(scPriceSubtotal)
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                      <b-td class="text-right py-0">{{
                        Number(quote.permit_fees).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                      <b-td class="quote-totals">{{
                        Number(
                          Number(
                            quote.construction_pm_buffer * 1 +
                              quote.design_ame_buffer * 1 +
                              Number(quote.engineering_fee_price) * 1
                          ) *
                            quote.sqft +
                            Number(quote.permit_fees) +
                            Number(
                              (!quote.ffe_toggle ? 0 : ffe_calc) + optionsFfePriceTotal
                            ) +
                            Number(
                              avitPriceTotal +
                                avitInstallationTotal +
                                optionsAvItPriceTotal
                            ) +
                            Number(
                              bCtrSubtotal +
                                siteConditionsConstructionPriceTotal +
                                optionsConstructionPriceTotal
                            ) +
                            Number(scPriceSubtotal)
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                    </b-tr>
                    <b-tr class="">
                      <th
                        class="text-right py-0"
                        style="font-weight: 400; font-size: 14px"
                      >
                        Cost
                      </th>
                      <b-td></b-td>
                      <b-td class="table-cost-values">{{
                        Number(
                          quote.construction_calc +
                            siteConditionsConstructionCostTotal +
                            optionsConstructionCostTotal
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                      <b-td class="table-cost-values">{{
                        Number(
                          (!quote.ffe_toggle ? 0 : ffeTotalCost + ffe_installation) +
                            optionsFfeCostTotal
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                      <b-td class="table-cost-values">{{
                        Number(
                          avitTotalCost + avitInstallationTotal + optionsAvItCostTotal
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                      <b-td class="table-cost-values">{{
                        Number(
                          Number(quote.engineering_fee_cost * 1 * quote.sqft) +
                            Number(scCostSubtotal)
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                      <b-td class="table-cost-values">{{
                        Number(
                          quote.permit_fees_cost_per_sqft * quote.sqft
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                      <b-td class="quote-totals-cost">{{
                        Number(
                          quote.construction_calc +
                            siteConditionsConstructionCostTotal +
                            optionsConstructionCostTotal +
                            ((!quote.ffe_toggle ? 0 : ffeTotalCost + ffe_installation) +
                              optionsFfeCostTotal) +
                            (avitTotalCost +
                              avitInstallationTotal +
                              optionsAvItCostTotal) +
                            quote.engineering_fee_cost * 1 * quote.sqft +
                            quote.permit_fees_cost_per_sqft * quote.sqft +
                            Number(scCostSubtotal)
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}</b-td>
                    </b-tr>
                  </tbody>
                  <!-- end quote markes as active sec -->
                  <!-- start CO active details -->
                  <tbody
                    v-for="(changeOrder, index) in approvedChangeOrders"
                    :key="index + 'revenue'"
                    style="border-width: 0"
                  >
                    <b-tr>
                      <th
                        rowspan="2"
                        colspan="3"
                        style="
                          width: 12%;
                          font-weight: 600;
                          color: #6e6b7b;
                          font-size: 14px;
                        "
                      >
                        Change Order {{ changeOrder.change_order_number }}
                      </th>
                      <th
                        class="text-right py-0"
                        style="font-weight: 400; font-size: 14px"
                      >
                        Revenue
                      </th>
                      <b-td class="text-right py-0" style="">
                        <p v-if="signed" class="mt-1">
                          {{ formattedDate(changeOrder.updated_at) }}
                        </p>
                        <p v-else class="mt-1" style="color: #ff9f43">
                          Not Approved
                        </p></b-td
                      >
                      <b-td class="text-right py-0" style="">{{
                        changeOrderConstructionPriceTotal(changeOrder)
                      }}</b-td>
                      <b-td class="text-right py-0" style="">{{
                        changeOrderFfePriceTotal(changeOrder)
                      }}</b-td>
                      <b-td class="text-right py-0" style="">{{
                        changeOrderAvItPriceTotal(changeOrder)
                      }}</b-td>
                      <b-td class="text-right py-0" style="">{{
                        changeOrderCHQPriceTotal(changeOrder)
                      }}</b-td>
                      <b-td class="table-cost-values text-right py-0" style="">{{
                        "$" +
                        Number(0.0).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                        })
                      }}</b-td>
                      <b-td class="quote-totals" style="">{{
                        changeOrderQuotePriceTotal(changeOrder)
                      }}</b-td>
                    </b-tr>
                    <b-tr>
                      <th
                        class="text-right py-0"
                        style="font-weight: 400; font-size: 14px"
                      >
                        Cost
                      </th>
                      <b-td></b-td>
                      <b-td class="table-cost-values">{{
                        changeOrderConstructionCostTotal(changeOrder)
                      }}</b-td>
                      <b-td class="table-cost-values">{{
                        changeOrderFfeCostTotal(changeOrder)
                      }}</b-td>
                      <b-td class="table-cost-values">{{
                        changeOrderAvItCostTotal(changeOrder)
                      }}</b-td>
                      <b-td class="table-cost-values">{{
                        changeOrderSoftCostsCostTotal(changeOrder)
                      }}</b-td>
                      <b-td class="table-cost-values">{{
                        "$" +
                        Number(0.0).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                        })
                      }}</b-td>
                      <b-td class="quote-totals-cost">{{
                        changeOrderCHQCostTotal(changeOrder)
                      }}</b-td>
                    </b-tr>
                  </tbody>
                  <!-- end CO details -->
                </table>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <!-- end header info -->
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BAlert,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";
import {
  VIcon,
  VMenu,
  VBtn,
  VList,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemTitle,
  VDivider,
  VListItemGroup,
  VRow,
  VCheckbox,
  VSimpleTable,
} from "vuetify/lib";
import {
  UilRuler,
  UilSuitcase,
  UilCheck,
  UilChartLine,
  UilRefresh,
  UilExclamationTriangle,
  UilFileExport,
} from "@iconscout/vue-unicons";
import moment from "moment";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    UilCheck,
    BDropdown,
    BDropdownItem,
    UilChartLine,
    UilRefresh,
    BCard,
    UilExclamationTriangle,
    BAlert,
    UilFileExport,
    VIcon,
    VMenu,
    VBtn,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemTitle,
    VDivider,
    VListItemGroup,
    VRow,
    VCheckbox,
    VSimpleTable,
    BTr,
    BTd,
    BTh,
  },
  props: {
    address: {
      type: String,
    },
    tender_address: {
      type: String,
    },
    signed: {
      type: Boolean,
    },
    quote: {
      type: Object,
    },
    active_tfr: {
      type: Object,
    },
    change_orders: {
      default: [],
    },
    opp_id: {
      type: String,
    },
  },
  data() {
    return {
      approvedChangeOrders: [],
      excluded_codes_contingency_fee: [
        "Z1070.30.01",
        "Z1070.30.02",
        "Z1070.30.03",
        "Z1070.40",
        "Z1070.50",
        "Z1070.60",
        "Z1070.80",
        "Z1050.20",
        "Z1050.25",
        "Z1050.30",
        "Z1020.10",
      ],
      excluded_codes_contingency_fee_v2:
          [
            "Z1070.30.03",
            "Z1070.60",
            "Z1020.10",
            "Z1020.10",
            "Z1070.30.02",
            "Z1070.30.02",
            "Z1070.60",
            "Z1050.35",
            "Z1070.20",
            "Z1010.10"
          ],
    };
  },
  computed: {
    ffeTotalCost() {
      const results = this.quote.ffe_spec_quote.reduce(
        (total, obj) =>
          (obj.specification_swap == null
            ? Number(obj.total_cost * 1)
            : obj.specification_swap.total_price * 1) + total,
        0
      );
      return results;
    },
    // add version validations
    avitTotalCost() {
      let results = 0;
      if (this.quote.base_spec_version < 3.3) {
        if (this.quote.avit_toggle) {
          results = this.quote.avit_spec_quote.reduce(
            (total, obj) =>
              (obj.specification_swap == null
                ? Number(obj.total_cost * 1)
                : obj.specification_swap.total_price * 1) + total,
            0
          );
        } else {
          results = 0;
        }
      } else {
        if (this.quote.av_toggle && this.quote.it_toggle) {
          results = this.quote.avit_spec_quote.reduce(
            (total, obj) =>
              (obj.specification_swap == null
                ? Number(obj.total_cost * 1)
                : obj.specification_swap.total_price * 1) + total,
            0
          );
        } else if (this.quote.av_toggle) {
          results = this.quote.avit_spec_quote
            .filter((n) => n.subcategory == "AV")
            .reduce(
              (total, obj) =>
                (obj.specification_swap == null
                  ? Number(obj.total_cost * 1)
                  : obj.specification_swap.total_price * 1) + total,
              0
            );
        } else if (this.quote.it_toggle) {
          results = this.quote.avit_spec_quote
            .filter((n) => n.subcategory == "IT")
            .reduce(
              (total, obj) =>
                (obj.specification_swap == null
                  ? Number(obj.total_cost * 1)
                  : obj.specification_swap.total_price * 1) + total,
              0
            );
        }
      }

      return results;
    },
    // get cstrn bc total prices
    bCtrSubtotal() {
      // * adding new fees for base spec 3.7+ (cm fee and new contingency fee)
      let result = this.quote.construction_spec_quote.reduce(
        (total, obj) => Number(obj.total_price) + this.contingencyFeeCalc(obj) + total,
        0
      );

      if (this.quote.cm_fee_enabled && this.quote.base_spec_version<4.2) {
        result += this.cmFeeCalc * 1;
      }else{
        result += this.cmFeeCalcUpdated * 1;
      }
      // retutn cstrn total based on spec version to work with 2.x quotes
      // dummy comment
      return this.quote.base_spec_version < 3
        ? this.quote.construction_buffer * 1 * this.quote.sqft +
            this.quote.construction_calc
        : result;
    },
    siteConditionsConstructionCostTotal() {
      const results = this.quote.site_conditions
        .filter((x) => x.budget_group === "Construction")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      return results;
    },
    siteConditionsConstructionPriceTotal() {
      const results = this.quote.site_conditions
        .filter((x) => x.budget_group === "Construction")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      return results;
    },
    // sum additions and Callowances
    optionsConstructionCostTotal() {
      let results = this.quote.options
        .filter((x) => x.budget_group === "Construction")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      results += this.quote.allowances
        .filter((x) => x.budget_group === "Construction")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      return results;
    },
    optionsConstructionPriceTotal() {
      // add new logic to calculate contingency fees for cstrn items and exclueded
      //gen reqs and close out
      // ticket link https://www.notion.so/clearspace1/Update-Construction-Contingency-39f209c0d85d41a0a4ffb359be48ec36?pvs=4
      let result = this.quote.options
        .filter((c) => c.budget_group == "Construction" && !c.is_base_spec)
        .reduce(
          (total, obj) => Number(obj.total_price)  + total,
          0
        );
      result += this.addBcSubtotal;
      result += this.quote.allowances
        .filter((x) => x.budget_group === "Construction")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      return result;
    },
    optionsAvItCostTotal() {
      let results = this.quote.options
        .filter((x) => x.budget_group === "AV/IT")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      results += this.quote.allowances
        .filter((x) => x.budget_group === "AV/IT")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      return results;
    },
    optionsAvItPriceTotal() {
      let results = this.quote.options
        .filter((x) => x.budget_group === "AV/IT")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      results += this.quote.allowances
        .filter((x) => x.budget_group === "AV/IT")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      return results;
    },
    optionsFfeCostTotal() {
      let results = this.quote.options
        .filter((x) => x.budget_group === "FF&E")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      results += this.quote.allowances
        .filter((x) => x.budget_group === "FF&E")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      return results;
    },
    optionsFfePriceTotal() {
      let results = this.quote.options
        .filter((x) => x.budget_group === "FF&E")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      results += this.quote.allowances
        .filter((x) => x.budget_group === "FF&E")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      return results;
    },
    ccdcQuoteRevenueTotal() {
      return Number(
        (this.quote.construction_pm_buffer * 1 +
          this.quote.design_ame_buffer * 1 +
          Number(this.quote.engineering_fee_price) * 1) *
          this.quote.sqft +
          Number(this.quote.permit_fees) +
          Number(
            (!this.quote.ffe_toggle ? 0 : this.ffe_calc) + this.optionsFfePriceTotal
          ) +
          Number(
            this.avitPriceTotal + this.avitInstallationTotal + this.optionsAvItPriceTotal
          ) +
          Number(
            this.bCtrSubtotal +
              this.siteConditionsConstructionPriceTotal +
              this.optionsConstructionPriceTotal
          ) +
          Number(this.scPriceSubtotal)
      );
    },
    ccdcQuoteCostTotal() {
      return Number(
        this.quote.construction_calc +
          this.siteConditionsConstructionCostTotal +
          this.optionsConstructionCostTotal +
          ((!this.quote.ffe_toggle ? 0 : this.ffeTotalCost + this.ffe_installation) +
            this.optionsFfeCostTotal) +
          (this.avitTotalCost + this.avitInstallationTotal + this.optionsAvItCostTotal) +
          this.quote.engineering_fee_cost * 1 * this.quote.sqft +
          this.quote.permit_fees_cost_per_sqft * this.quote.sqft +
          Number(this.scCostSubtotal)
      );
    },
    revenueTotal() {
      let changeOrdersTotal = 0;
      this.change_orders.forEach((e) => {
        changeOrdersTotal += parseFloat(
          e.change_order_lines.reduce((total, obj) => obj.total_price * 1 + total, 0)
        );
        changeOrdersTotal += e.admin_fee_enabled ? e.admin_fee_subtotal : 0;
      });
      changeOrdersTotal += this.ccdcQuoteRevenueTotal;
      return parseFloat(changeOrdersTotal.toFixed(2));
    },
    costTotal() {
      let changeOrdersTotal = 0;
      this.change_orders.forEach((e) => {
        changeOrdersTotal += parseFloat(
          e.change_order_lines.reduce((total, obj) => obj.total_cost * 1 + total, 0)
        );
      });
      changeOrdersTotal += this.ccdcQuoteCostTotal;
      return parseFloat(changeOrdersTotal.toFixed(2));
    },
    scPriceSubtotal() {
      let result = 0;
      result += this.quote.allowances
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      result += this.quote.options
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    scCostSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      result += this.quote.options
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return result;
    },
    // add version validations
    avitInstallationTotal() {
      let sum = 0;
      if (this.quote.base_spec_version < 3.3) {
        sum += this.quote.avit_toggle ? this.avitDI("IT") : 0;
        sum += this.quote.avit_toggle ? this.avitDI("AV") : 0;
      } else {
        sum += this.quote.it_toggle ? this.avitDI("IT") : 0;
        sum += this.quote.av_toggle ? this.avitDI("AV") : 0;
      }
      return sum;
    },
    // add version validations
    avitPriceTotal() {
      let sum = 0;
      if (this.quote.base_spec_version < 3.3) {
        sum += this.quote.avit_toggle ? this.avitTotals("IT") : 0;
        sum += this.quote.avit_toggle ? this.avitTotals("AV") : 0;
      } else {
        sum += this.quote.it_toggle ? this.avitTotals("IT") : 0;
        sum += this.quote.av_toggle ? this.avitTotals("AV") : 0;
      }
      return sum;
    }, // add ffe calc
    ffe_calc() {
      let sum = 0;
      sum = this.quote.ffe_spec_quote.reduce(
        (total, obj) =>
          (obj.specification_swap == null
            ? Number(obj.total_price)
            : obj.specification_swap.total_price * 1 +
              obj.specification_swap.total_delivery_install * 1) + total,
        0
      );
      return sum;
    },
    // ffe D&I calc
    ffe_installation() {
      let sum = 0;
      sum = this.quote.ffe_spec_quote.reduce(
        (total, obj) =>
          (obj.specification_swap == null
            ? Number(obj.total_delivery_install)
            : obj.specification_swap.total_delivery_install * 1) + total,
        0
      );
      return sum;
    },
    // add total cost for cm fee requirements
    constructionTotalCost() {
      var sum = 0;
      this.quote.construction_spec_quote.forEach((e) => {
        sum += parseFloat(e.total_cost);
      });

      this.quote.options
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_cost);
        });

      this.quote.site_conditions
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_cost);
        });

      return Number(sum).toFixed(2);
    },
    // change logic to calculate cmfee calc
    // ticket https://www.notion.so/clearspace1/Contingency-CM-Fee-Formulas-302caff0f00b4c0d999e65f43f025d4b
    baseSpecItemsSubTotal() {
      var sum = 0;
      // add validation for cm fee and cstrn margin per item
      this.quote.construction_spec_quote.forEach((e) => {
        sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
      });

      sum += this.addBcSubtotal;

      this.quote.site_conditions
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return Number(sum).toFixed(2);
    },
    addBcSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Construction" && c.is_base_spec)
        .reduce(
          (total, obj) =>
            Number(obj.total_price) + parseFloat(this.contingencyFeeCalc(obj)) + total,
          0
        );
      return result;
    },
    // cm fee calc
    cmFeeCalc() {
      let result = 0;
      if (this.quote.base_spec_version > 3.6) {
        result = this.quote.cm_fee_enabled
          ? Number(this.baseSpecItemsSubTotal) *
            (Number(this.quote.cm_fee_percentage) / 100)
          : 0;
      }
      return result;
    },

    //function to apply cm fee globally for quotes 4.2 and above
    cmFeeCalcUpdated() {
      let result = 0;
      //substract base spec items addditions to avoid duplicate values sume
      let base_spec_items_constrn=0
      this.quote.options
          .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
          .forEach((e) => {
            base_spec_items_constrn += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
          });
      if (this.quote.base_spec_version >= 4.2) {
        result = this.quote.cm_fee_enabled
            ? Number(Number(this.baseSpecItemsSubTotal)-Number(base_spec_items_constrn)) *
            (Number(this.quote.cm_fee_percentage) / 100)
            : 0;
      }
      if(this.quote.base_spec_version >= 4.2 && this.quote.cm_fee_enabled ){
        result += this.quote.options.filter(
                x => x.budget_group == 'Construction',
            ).reduce((total, o) => o.total_price * 1 + total, 0)*
            (Number(this.quote.cm_fee_percentage) / 100);

        result += this.quote.allowances.filter(
                x => x.budget_group == 'Construction',
            ).reduce((total, o) => o.total_price * 1 + total, 0)*
            (Number(this.quote.cm_fee_percentage) / 100);
      }
      if(this.quote.base_spec_version < 4.2){
        result = 0;
      }
      return result;
    },
  },
  mounted() {
    this.updateBreadcrumb();
    if (this.change_orders.length > 0) {
      this.approvedChangeOrders = this.change_orders.filter(
        (c) => c.status == "Approved"
      );
    }
  },
  methods: {
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[1].text = `${this.address}`;
      this.$route.meta.breadcrumb[2].active = true;
      this.$route.meta.breadcrumb[2].text = `Finance`;
      this.$route.meta.breadcrumb[2].active = false;
      this.$root.$emit("rerender-breadcrumb");
    },
    financeExport() {
      this.$http({
        url: `/opportunities/${this.opp_id}/finance-export`,
        method: "GET",
        responseType: "blob",
      }).then((res) => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${this.tender_address} - Finance Export.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    changeOrderConstructionCostTotal(changeOrder) {
      const total = changeOrder.change_order_lines
        .filter((x) => x.budget_group === "Construction")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      const formattedNumber =
        this.numberSymbol(total) +
        Number(Math.abs(total)).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        });
      return formattedNumber;
    },
    changeOrderConstructionPriceTotal(changeOrder) {
      const total = changeOrder.change_order_lines
        .filter((x) => x.budget_group === "Construction")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      const formattedNumber =
        this.numberSymbol(total) +
        Number(Math.abs(total)).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        });
      return formattedNumber;
    },
    changeOrderFfeCostTotal(changeOrder) {
      const total = changeOrder.change_order_lines
        .filter((x) => x.budget_group === "FF&E")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      const formattedNumber =
        this.numberSymbol(total) +
        Number(Math.abs(total)).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        });
      return formattedNumber;
    },
    changeOrderFfePriceTotal(changeOrder) {
      const total = changeOrder.change_order_lines
        .filter((x) => x.budget_group === "FF&E")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      const formattedNumber =
        this.numberSymbol(total) +
        Number(Math.abs(total)).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        });
      return formattedNumber;
    },
    changeOrderAvItCostTotal(changeOrder) {
      const total = changeOrder.change_order_lines
        .filter((x) => x.budget_group === "AV/IT")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      const formattedNumber =
        this.numberSymbol(total) +
        Number(Math.abs(total)).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        });
      return formattedNumber;
    },
    changeOrderAvItPriceTotal(changeOrder) {
      const total = changeOrder.change_order_lines
        .filter((x) => x.budget_group === "AV/IT")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      const formattedNumber =
        this.numberSymbol(total) +
        Number(Math.abs(total)).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        });
      return formattedNumber;
    },
    changeOrderSoftCostsCostTotal(changeOrder) {
      const total = changeOrder.change_order_lines
        .filter((x) => x.budget_group === "Soft Costs")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      const formattedNumber =
        this.numberSymbol(total) +
        Number(Math.abs(total)).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        });
      return formattedNumber;
    },
    changeOrderCHQPriceTotal(changeOrder) {
      let total = changeOrder.change_order_lines
        .filter((x) => x.budget_group === "Soft Costs")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      let totalAdminFee = changeOrder.admin_fee_enabled
        ? changeOrder.admin_fee_subtotal
        : 0;
      total += totalAdminFee;
      const formattedNumber =
        this.numberSymbol(total.toFixed(2)) +
        Number(Math.abs(total.toFixed(2))).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        });
      return formattedNumber;
    },
    changeOrderCHQCostTotal(changeOrder) {
      const total = changeOrder.change_order_lines.reduce(
        (total, obj) => obj.total_cost * 1 + total,
        0
      );
      const formattedNumber =
        this.numberSymbol(total) +
        Number(Math.abs(total)).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        });
      return formattedNumber;
    },
    changeOrderQuotePriceTotal(changeOrder) {
      let total = changeOrder.change_order_lines.reduce(
        (total, obj) => obj.total_price * 1 + total,
        0
      );
      let totalAdminFee = changeOrder.admin_fee_enabled
        ? changeOrder.admin_fee_subtotal
        : 0;
      total += totalAdminFee;
      const formattedNumber =
        this.numberSymbol(total.toFixed(2)) +
        Number(Math.abs(total.toFixed(2))).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        });
      return formattedNumber;
    },
    formattedDate(date) {
      return moment(date).format("MMMM DD, YYYY");
    },
    numberSymbol(number) {
      return [number < 0 ? "-$" : "$"];
    },
    avitTotals(option) {
      let total = 0;
      total = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.supply_price * obj.qty)
              : obj.specification_swap.total_price * 1) + total,
          0
        );
      return total;
    },
    avitDI(option) {
      let total = 0;
      total = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.total_delivery_install * 1)
              : obj.specification_swap.total_delivery_install * 1) + total,
          0
        );
      return total;
    },

    // calculate base spec adjustment qty
    constructionAdjustmentTotalQuantity(constructionWorkId) {
      var sum = 0;
      this.quote.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.quantity);
        });
      return parseFloat(sum);
    },
    contingencyFeeCalc(construction_spec) {
      let result =
        this.quote.base_spec_version > 3.6
          ? Number(
              Number(construction_spec.total_cost) *
                (this.quote.contingency_fee_percentage / 100)
            )
          : 0;
      if (
          this.excluded_codes_contingency_fee.filter(
              (e) => e == construction_spec.uniformat
          ).length > 0 && this.quote.base_spec_version > 3.6 && this.quote.base_spec_version <=4.1
      ) {
        result = 0;
      }

      if (
          this.excluded_codes_contingency_fee_v2.filter(
              (e) => e == construction_spec.uniformat
          ).length > 0 && this.quote.base_spec_version >= 4.2
      ) {
        result = 0;
      }
      // create custom adjustment for elements that qty is adjusted to 0
      if (
      (  construction_spec.qty +
          this.constructionAdjustmentTotalQuantity(
            construction_spec.construction_work_id
          ) )==
        0
      ) {
        result = 0;
      }
      let cstrn_iten_filter = this.quote.construction_spec_quote.find((c)=>c.construction_work_id==construction_spec.construction_work_id);
      if (cstrn_iten_filter&&
        cstrn_iten_filter.qty +
          this.constructionAdjustmentTotalQuantity(
            construction_spec.construction_work_id
          ) ==
        0
      ) {
        result = 0;
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  border: none;
  border-left: 1px solid rgba(31, 41, 47, 0.05);
  height: 6vh;
  width: 1px;
  margin-right: 25px;
  margin-top: -1vh;
}
.card_title {
  color: #b9b9c3;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 12px;
}
.cost-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #b9b9c3;
}
.cost-header {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #b9b9c3;
}

.header-subtotals {
  font-weight: 500 !important;
  font-size: 21px !important;
  line-height: 21px !important;
  color: #6e6b7b !important;
}

.table-cost-values {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: #b9b9c3;
}

.quote-totals {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color:  rgba(59, 171, 255, 1) ;
}

.quote-totals-cost {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: #b9b9c3;
}
.table-background-color {
  background-color: rgba(92, 177, 231, 0.12) !important;
  text-transform: uppercase;
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>

<style lang="scss" scoped>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: rgba(50, 61, 76, 1) !important;
}
</style>
